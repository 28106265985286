<template>
    <el-card class="v_store_list">
      <div class="g-flex" slot="header">
        <span>商家账号黑名单列表</span>
      </div>
      <el-form :inline="true" size="mini" :model="query">
        <el-form-item>
          <el-select slot="label" v-model="query.search_key" placeholder="查询">
            <el-option label="商家id" value="user_id"></el-option>
            <el-option label="买家账号id" value="user_account_id"></el-option>
          </el-select>
          <el-row>
            <el-col :span="8">
              <el-input v-model="query.search_val" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
            </el-col>
            <el-col :span="2" :offset="1">
              <el-button type="info" @click="resetData">重置</el-button>
            </el-col>
            <el-col :span="2" :offset="1">
              <el-button type="primary" @click="getList()">查询</el-button>
            </el-col>
            <el-col :span="3" :offset="1">
              <el-button type="danger"  @click="removeBlack(removeIds)">批量移除</el-button>
            </el-col>
            <el-col :span="3" :offset="1">
              <el-button type="success" @click="addBlackClick">新增账号黑名单</el-button>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <el-table @selection-change="handleSelectionChange"  stripe size="mini" v-loading="table.loading" :data="table.rows" border>
       <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column label="商家ID" :show-overflow-tooltip="true"> 
          <template slot-scope="scope">
            <span>{{ scope.row.user_id }} </span>
          </template>
        </el-table-column>
        <el-table-column label="商家手机号" :show-overflow-tooltip="true"> 
          <template slot-scope="scope">
            <span>{{ scope.row.user.mobile }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="user_shop_id" label="账号类型" :show-overflow-tooltip="true"> 
          <template slot-scope="scope">
            <span>{{ scope.row.userAccount.platform_id | filtersPlatName(shopTypeList)}} </span>
          </template>
        </el-table-column>

        <el-table-column prop="user_shop_id" label="买家账号id" :show-overflow-tooltip="true"> 
          <template slot-scope="scope">
            <span>{{ scope.row.user_account_id }} </span>
          </template>
        </el-table-column>

        <el-table-column prop="user_shop_id" label="买家账号" :show-overflow-tooltip="true"> 
          <template slot-scope="scope">
            <span>{{ scope.row.userAccount.account }} </span>
          </template>
        </el-table-column>

        <el-table-column label="加入黑名单原因" width="200" :show-overflow-tooltip="true">
          <template slot-scope="scope">
              <el-input type="textarea" readonly :autosize="{ minRows: 1, maxRows: 2}" placeholder="请输入内容" v-model="scope.row.remark"></el-input>
            </template>
        </el-table-column>
        <el-table-column label="创建时间" width="140" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>{{formatDate(scope.row.create_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
          </template>
          
        </el-table-column>
        <el-table-column label="到期时间" width="140" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span class="g-red">{{formatDate(scope.row.end_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="removeBlack(scope.row.id)" >移出黑名单</el-button>
            <el-button size="mini" type="danger" @click="setForm(scope.row)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination :page-sizes="[15, 30, 60, 100]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange" small hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>

      <account-store-black-list-pop ref="AccountStoreBlackListPop" :shopTypeList="shopTypeList" @emitSaveSuccess="getList(false)"/>

      <account-store-black-list-add-pop ref="AccountStoreBlackListAddPop" :shopTypeList="shopTypeList" @emitSaveSuccess="getList(false)"/>
    </el-card>
</template>

<script>
  import { apiGetShopTypeList, apiGetStoreBlackList, apiDelStoreAccountBlack } from '@/utils/api.js'
  import { formatDate } from '@/utils/index.js'
  import AccountStoreBlackListPop from './AccountStoreBlackListPop.vue'
  import AccountStoreBlackListAddPop from './AccountStoreBlackListAddPop'
  export default {
  components: { AccountStoreBlackListPop, AccountStoreBlackListAddPop },
  async created(){
    await this.apiGetShopTypeListHandel()
    await this.getList()
  },
  data() {
    return {
      shopTypeList: [],

      auth: {
        saveRole: this.$global.ApiList.includes(25),
        delRole: this.$global.ApiList.includes(26),
      },

      query: { search_key: 'user_id', search_val: '', page:1, limit:15 },

      table:{ loading:false, total:0, rows:[] },

      form: false,

      formData: {},

      roleMenu:false,
      role_id:0,
      MenuList:[],
      
      removeIds: ''
    }
  },
  filters: {
    filtersPlatName(id, list) {
      let obj = list.find(item => item.id == id)
      if(obj) return obj.title
      return ''
    },
  },
  methods: {
    formatDate,
    // 移除黑名单
    removeBlack(ids) {
      if(!ids) return this.$message.error('请选择要移除黑名单的账号')
      this.$confirm('此操作回将账号移出黑名单, 是否继续?', '移出黑名单', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.apiDelStoreAccountBlackHandel(ids)
      }).catch(() => {});
    },

    // 移除黑名单
    async apiDelStoreAccountBlackHandel(ids) {
      this.table.loading = true
      const { success, data } = await apiDelStoreAccountBlack({ ids }).catch(() => this.table.loading = false)
       this.table.loading = false
      if(!success) return
      this.$message.success(data.msg)
      this.getList(false)
      console.log(data)
    },
    handleSelectionChange(val) {
      this.removeIds = val.map(item => item.id).join(',')
      console.log(this.removeIds)
    },
    addBlackClick() {
      this.$refs.AccountStoreBlackListAddPop.onShow()
    },
    // 审核
    passClick(id, isPassword) {
      if(isPassword) return this.$confirm('此操作回将该店铺审核通过, 是否继续?', '店铺审核', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.apiUpdateStoreStatusHandel({ id: id, status: 2 })
        }).catch(() => {});

      if(!this.isPassword) return this.$prompt('请输入审核不通过理由：', '店铺审核', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern:  /\S/,
          inputErrorMessage: '理由不能为空'
        }).then(({ value }) => {
          this.apiUpdateStoreStatusHandel({ id: id, status: 0, remark: value })
        }).catch(() => {});
    },

    // 获取店铺类型
    async apiGetShopTypeListHandel() {
      const { success, data } = await apiGetShopTypeList()
      if(!success) return
      this.shopTypeList = data.list
      console.log(data)
    },

    //获取列表
    async getList(pageInit = true) {
      if (pageInit) this.query.page = 1
      this.table.loading = true
      const { success, data } = await apiGetStoreBlackList(this.query)
      if(!success) return this.table.loading = false
      this.table.loading = false
      this.table.rows = data.rows
      this.table.total = data.total
    },

    //每页数量改变
    sizeChange(limit) {
      this.query.limit = limit
      this.getList()
    },

    //设置form
    setForm(formData = {}, status = 2) {
      console.log(formData)
      let popDate = JSON.parse(JSON.stringify(formData))
      this.$refs.AccountStoreBlackListPop.onShow(popDate, status)
    },
    // 点击重置
    resetData() {
      this.query = { search_key: 'user_id', search_val: '', page:1, limit:15 },
      this.getList()
    },

  }
}
</script>

<style lang="scss">
</style>