<template>
 <div class="s_user_cash_out_list_pop">
    <el-dialog :title="seeStatus == 1 ? '查看详情': '查看详情'" class="s-task-dialog" top="30px" width="1100px" :close-on-click-modal="false" :visible.sync="show">
        <el-form :model="formData" label-width="140px" size="mini">
          <el-tabs v-model="activeName" type="card">
            <!-- 商家信息 -->
            <el-tab-pane label="商家账号信息" name="1">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="商家账号ID:">
                      <span>{{ formData.user_account_id }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="商家手机号:">
                      <span>{{ formData.user.mobile }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                    <el-form-item label="加入黑名单原因:">
                      <span>{{ formData.remark }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="创建时间:">
                      <span>{{formatDate(formData.create_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="到期时间:">
                      <span>{{formatDate(formData.end_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
            </el-tab-pane>
            <!-- 买家信息 -->
            <el-tab-pane label="买家账号信息" name="2" class="s-tabs-content-two">
               <el-row>
                  <el-col :span="8">
                    <el-form-item label="买家平台账号ID:">
                      <span>{{ formData.user_account_id }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                      <el-form-item label="账号名称:">
                        <span>{{formData.userAccount.account}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="所属平台:">
                        <span>{{formData.userAccount.platform_id | filtersPlatName(shopTypeList) }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="收货人姓名:">
                        <span>{{formData.userAccount.name}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="收货人手机:">
                        <span>{{formData.userAccount.mobile}}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="收货人地区:">
                        <span>{{formData.userAccount.region}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="收货人详细地址:">
                        <span>{{formData.userAccount.address}}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!--  只有淘宝 京东 xinyu_img 需要展示信誉等级和截图 -->
                  <el-row v-if="formData.userAccount.platform_id == 1 || formData.userAccount.platform_id == 2">
                    <el-col :span="8">
                      <el-form-item label="信誉截图:">
                        <el-image style="width: 100px; height: 100px" fit="cover" :src="formData.userAccount.xinyu_img" :preview-src-list="[formData.userAccount.xinyu_img]"></el-image>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!--如果是淘宝 如果开通花呗了 credit_pay_img 就要展示花呗截图 -->
                  <el-row v-if="formData.userAccount.platform_id == 1">
                    <el-col :span="8">
                      <el-form-item label="开通花呗:">
                        <span>{{ formData.userAccount.credit_pay | filtersCreditPay }}</span>
                      </el-form-item>
                    </el-col>
                    <!-- 花呗没开通就不要展示花呗截图 -->
                    <el-col :span="8" v-if="formData.userAccount.credit_pay">
                      <el-form-item label="花呗截图:">
                        <el-image  style="width: 100px; height: 100px" fit="cover" :src="formData.userAccount.credit_pay_img" :preview-src-list="[formData.userAccount.credit_pay_img]"></el-image>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!-- 如果是京东 如果开通了白条 credit_pay_img 就要展示白条截图 -->
                  <el-row v-if="formData.userAccount.platform_id == 2">
                    <el-col :span="8">
                      <el-form-item label="开通白条:">
                        <span>{{ formData.userAccount.credit_pay | filtersCreditPay }}</span>
                      </el-form-item>
                    </el-col>
                    <!-- 白条没开通就不要展示白条截图 -->
                    <el-col :span="8" v-if="formData.userAccount.credit_pay">
                      <el-form-item label="白条截图:">
                        <el-image  style="width: 100px; height: 100px" fit="cover" :src="formData.userAccount.credit_pay_img" :preview-src-list="[formData.userAccount.credit_pay_img]"></el-image>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <!-- 如果是京东 account_img 叫做账户截图 -->
                  <el-row>
                    <el-col :span="8" v-if="formData.userAccount.platform_id == 2 || formData.userAccount.platform_id == 3">
                      <el-form-item label="用户名/账户截图:">
                        <el-image  style="width: 100px; height: 100px" fit="cover" :src="formData.userAccount.account_img"></el-image>
                      </el-form-item>
                    </el-col>
                    <!-- 实名认证/qq/微信截图 real_img 必填的 -->
                      <el-col :span="8">
                      <el-form-item label="实名认证截图:">
                        <el-image style="width: 100px; height: 100px" fit="cover" :src="formData.userAccount.real_img" :preview-src-list="[formData.userAccount.real_img]"></el-image>
                      </el-form-item>
                    </el-col> 
                  </el-row>
            
            </el-tab-pane>
          </el-tabs>

          <el-row class="s-user-remark">
            <el-col :span="18">
              <el-form-item label="加入黑名单原因:">
                  <el-input placeholder="请输入加入黑名单原因" type="textarea" v-model="formData.remark" :autosize="{ minRows: 4, maxRows: 10 }" maxlength="100" show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div slot="footer" class="dialog-footer">

            <el-button @click="show = false" v-show="seeStatus === 2">取 消</el-button>
            <el-button type="primary" @click="show = false" v-show="seeStatus === 2">确定</el-button>
        </div>
    </el-dialog>

 </div>
</template>

<script>
import { formatDate } from '@/utils/index.js'
export default {
  props: {
    shopTypeList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      seeStatus: 1, //1 审核 2查看
      query: {
        page:1, limit: 15, user_id: ''
      },

      table:{ loading:false, total:0, rows:[] },

      formData: {
        user: {},
        userAccount: {},
        id: '',
        img: [],
        remark: '',
        userShop: { account : '' },
        platform: { title: '' },
        platformPlay: { title: '' },
        goods: [],
        task_type: { title: '' },
        search_keyword: [],
        entrance_pic: [],
        xdsj: { title: '' },
        huobi: { title: '' },
        liulan: { title: '' },
        main_liulan: [{ title: '' }],
        explain_img: [],
        xinyu: { title: '' },
        shop_limit: 0,
        account_limit: 0,
        close_time: 0,
        time_interval: 0,
        time_val: 0
      },
      show: false,
      activeName: '1',
      // 任务回收时间
      taskTimeList: [
        { name: '2小时', value: 2 },
        { name: '4小时', value: 4 },
        { name: '8小时', value: 8 },
        { name: '12小时', value: 12 },
        { name: '24小时', value: 24 },
        { name: '48小时', value: 48 },
        { name: '当天24点', value: 0 },
      ],
    }
  },
  filters: {
    filtersPlatName(id, list) {
      let obj = list.find(item => item.id == id)
      if(obj) return obj.title
      return ''
    },
    filtersCreditPay(pay) {
      if(pay == 1) return '已开通'
      return '未开通'
    },
  },
  methods: {
    formatDate,
    // status //1 审核 2查看
    onShow(data, status) {
      console.log(status)
      this.seeStatus = status
      console.log(this.formData)
      this.formData = data
      this.show = true
    },
    onClose() {
      this.show = false
    }
  }
}
</script>

<style lang='scss'>
.s_user_cash_out_list_pop {
  .el-dialog {
    max-height: 920px;
    margin: 0 auto;
  }
  .s-tabs-content-two {
    padding-bottom: 20px;
  }
  .s-user-cash-ou-list-pop-table {
    height: 500px;
    overflow: auto;
  }
  .s-user-remark {
    margin-top: 10px;
  }
}
</style>